import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  initialOrderCreate,
  sessionCall,
  setReferrer,
} from '../../store/action/OrderAction';
import ServiceabilityCheck from './ServiceabilityCheck';
import { BackdropLoader } from '../common/Loader';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { OrderStatus } from '../../util/util';
import { useSessionData } from '../../hooks/order';
import {
  getHomeUrl,
  isUndefinedNullOrEmpty,
  setSessionOrderId,
} from '../../common/common';

const HomePage = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const { sessionResponse } = useSessionData();
  const { Euid = '', gid = '' ,referrer = '' } = queryString.parse(location.search);
  const GID = (sessionResponse && sessionResponse.uid) || Euid ? Euid : gid;


  const addTfnFlipScript = () => {
    let script = document.createElement('script');

    script.src = 'https://api.earthlink.com/tfn-campaigns/scripts/tfns.js';

    document.body.appendChild(script);
  };
  useEffect(()=>{
    if(referrer){
      dispatch(setReferrer(referrer))
    } else{
      dispatch(setReferrer(''))
    }
  },[referrer])
  useEffect(() => {
    addTfnFlipScript();
    if (!isUndefinedNullOrEmpty(GID)) {
      dispatch(sessionCall(GID))
        .then((response) => {
          const {
            agentId,
            partnerId,
            businessPromoCode,
            affiliateId,
            promoCode,
            businessAddress,
            homeUrl,
          } = response.value.data;
          let body = {
            orderStatus: OrderStatus.newSession,
            affiliateUserId: agentId,
            partnerName: partnerId,
          };
          if (homeUrl.includes('switch.earthlink')) {
            body.trackingAttrs = {
              cj: {
                AID: String(affiliateId),
                PID: businessAddress ? businessPromoCode : promoCode,
                CID: '1559416',
              },
            };
          }
          if(referrer){
            dispatch(setReferrer(referrer))
          }
          if (agentId === 'lp_allconnect') {
            body.trackingAttrs = {
              acsid: queryString.parse(location.search).acsid,
            };
          }
          dispatch(initialOrderCreate(body))
            .then((res) => {
              setSessionOrderId(res.value.data.id);
              setLoader(false);
            })
            .catch(() => {
              window.location.href =
                getHomeUrl() || 'https://www.earthlink.net/internet/';
            });
        })
        .catch(() => {
          window.location.href =
            getHomeUrl() || 'https://www.earthlink.net/internet/';
        });
    } else {
      setLoader(false);
    }
  }, [dispatch, GID]);

  if (loader) {
    return <BackdropLoader />;
  }
  return <ServiceabilityCheck {...props} />;
};

export default React.memo(HomePage);
